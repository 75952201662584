import {type Pagination} from "@hosttools/core/shared/model/pagination";
import {memoWithType, useToast} from "@hosttools/frontend";
import type {QueryObserverBaseResult} from "@tanstack/react-query";
import {useQuery} from "@tanstack/react-query";
import type {
    ColumnDef,
    SortingState,
    TableState,
    Row,
    Column,
    ColumnFiltersState,
    FiltersTableState,
    RowSelectionState,
    Table as TableRT,
    VisibilityState,
    PaginationState
} from "@tanstack/react-table";
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getFilteredRowModel,
    getSortedRowModel
} from "@tanstack/react-table";
import {ArrowDownIcon, Button, HStack, Stack, VStack, View} from "native-base";
import React, {
    type Ref,
    type ReactNode,
    forwardRef,
    useMemo,
    useState,
    useImperativeHandle,
    useEffect,
    useCallback,
    useRef
} from "react";

import {Table, TableCell, TableHeader, TableRow, TablePagination} from "../Table";

import {defaultPageSize} from "./constant";
import EditColumns from "./EditColumns";
import EmptyState from "./EmptyState";
import FilterDateRange from "./FilterDateRange";
import FilterSelect from "./FilterSelect";
import FilterText from "./FilterText";
import Skeleton from "./Skeleton";

import {type RequestPayload} from "@/admin/hooks/usePaginatedFetch";
import useMobileView from "@/client/hooks/useMobileView";

export type FetchDataOptions = RequestPayload &
    FiltersTableState & {
        sorting: SortingState;
    };

interface BaseProps<T> {
    testID?: string;
    enableGlobalFilter?: boolean;
    enableVisibility?: boolean;
    enableRowSelection?: boolean | ((row: Row<T>) => boolean);
    enableMultiRowSelection?: boolean;
    pageNumber?: number;
    pageSize?: number;
    hiddenColumns?: (keyof T)[];
    gridTemplateColumns?: React.CSSProperties["gridTemplateColumns"];
    variant?: "table" | "list";
    exportData?: (payload: FetchDataOptions) => Promise<void>;
    onRowClick?: (row: Row<T>) => void;
    onStateChange?: (state: TableState) => void;
}

interface PropsOffline<T> extends BaseProps<T> {
    mode: "offline";
    data: T[];
    columns: ColumnDef<T, string>[];
    rowId?: keyof T;
}

interface PropsOnline<T> extends BaseProps<T> {
    mode: "online";
    queryKey: string;
    columns: ColumnDef<T, any>[];
    queryFn: (payload: FetchDataOptions) => Promise<Pagination<T> | undefined>;
    rowId?: keyof T;
}

export interface RefValue<T> {
    table: TableRT<T>;
    refetch: QueryObserverBaseResult<Pagination<T> | undefined>["refetch"];
}

type Props<T> = StrictUnion<PropsOffline<T> | PropsOnline<T>>;

const TableFetch = forwardRef(
    (
        {
            testID,
            columns,
            rowId,
            enableGlobalFilter = true,
            enableRowSelection = true,
            enableMultiRowSelection = true,
            mode,
            data: offlineData,
            queryKey,
            pageNumber = 1,
            pageSize = defaultPageSize,
            enableVisibility,
            hiddenColumns,
            gridTemplateColumns,
            variant = "table",
            exportData,
            queryFn,
            onStateChange,
            onRowClick
        },
        ref
    ) => {
        const isMobile = useMobileView();
        const toast = useToast();
        const [globalFilter, setGlobalFilter] = useState<FiltersTableState>();
        const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(
            hiddenColumns
                ? hiddenColumns.reduce((arr, elem) => {
                      arr[elem] = false;
                      return arr;
                  }, {} as VisibilityState)
                : {}
        );
        const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
        const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
        const [pagination, setPagination] = useState<PaginationState>({
            pageIndex: pageNumber - 1,
            pageSize
        });
        const [sorting, setSorting] = React.useState<SortingState>([]);
        const [totalPages, setTotalPages] = useState<number | undefined>();
        const [exportLoading, setExportLoading] = useState<boolean>();
        const fetchDataOptions: FetchDataOptions = useMemo(
            () => ({
                offset: pagination.pageIndex * pagination.pageSize,
                limit: pagination.pageSize,
                sorting,
                columnFilters,
                globalFilter
            }),
            [pagination, columnFilters, globalFilter, sorting]
        );

        const {data, isLoading, refetch} = useQuery({
            queryKey: [...(mode === "online" ? [queryKey] : []), fetchDataOptions],
            enabled: mode === "online",
            queryFn: async () => {
                if (mode === "online") {
                    return queryFn(fetchDataOptions);
                }
            }
        });

        const docs = data?.docs;

        const tableData = useMemo(() => {
            if (mode === "offline") {
                return offlineData;
            }
            return isLoading ? (Array(pageSize).fill({}) as NonNullable<typeof docs>) : docs ?? [];
        }, [isLoading, pageSize, docs, mode, offlineData]);

        const tableColumns = useMemo(() => {
            if (mode === "offline") {
                return columns;
            }
            return isLoading
                ? (columns.map(column => ({
                      ...column,
                      header: column.header,
                      cell: () => (<Skeleton width={20} />) as ReactNode
                  })) as typeof columns)
                : columns;
        }, [isLoading, mode, columns]);

        const state = useMemo(
            () => ({
                pagination,
                rowSelection,
                columnFilters,
                globalFilter,
                columnVisibility,
                sorting
            }),
            [pagination, rowSelection, columnFilters, globalFilter, columnVisibility, sorting]
        );

        const table = useReactTable({
            getRowId: rowId ? row => row[rowId] : undefined,
            columns: tableColumns,
            data: tableData,
            manualFiltering: mode === "online",
            manualSorting: mode === "online",
            manualPagination: mode === "online",
            pageCount: mode === "online" ? totalPages ?? -1 : undefined,
            state,
            enableGlobalFilter,
            // not sure if this should only get set for offline mode
            autoResetPageIndex: false,
            enableColumnFilters: true,
            enableMultiRowSelection,
            enableRowSelection,
            onSortingChange: setSorting,
            onRowSelectionChange: setRowSelection,
            onPaginationChange: setPagination,
            onColumnFiltersChange: setColumnFilters,
            onGlobalFilterChange: setGlobalFilter,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            // automatically sorting
            getSortedRowModel: getSortedRowModel(),
            onColumnVisibilityChange: setColumnVisibility,
            debugTable: __DEV__
        });

        // this makes code more safer
        const onStateChangeRef = useRef(onStateChange);
        onStateChangeRef.current = onStateChange;

        // this effect looks unsafe
        useEffect(() => {
            const value = table.getState();
            onStateChangeRef.current?.(value);
        }, [state, table]);

        useImperativeHandle(
            ref,
            () => ({
                table,
                refetch
            }),
            [refetch, table]
        );
        const filterColumns = useMemo(() => {
            const result: Column<{}, unknown>[] = [];
            table.getHeaderGroups().forEach(group => {
                group.headers.forEach(({column}) => {
                    if (column.getCanFilter() && column.columnDef.filter) {
                        result.push(column);
                    }
                });
            });
            return result;
        }, [table]);

        const handleExportData = useCallback(async () => {
            setExportLoading(true);
            try {
                await exportData?.(fetchDataOptions);
                toast.success("Exported reservations");
            } finally {
                setExportLoading(false);
            }
        }, [exportData, fetchDataOptions, toast]);

        const isEmpty =
            mode === "online" ? !isLoading && !tableData.length : table.getPageCount() === 0;

        useImperativeHandle(
            ref,
            () => ({
                table,
                refetch
            }),
            [refetch, table]
        );

        // keep track the loaded `totalPages` because it turns empty as start loading with new key
        useEffect(() => {
            if (typeof data?.totalPages === "number") {
                setTotalPages(data.totalPages);
            }
        }, [data?.totalPages]);

        // handle online mode
        // go to previous page in case of no more data of current page
        const hasNoData = !tableData.length && !isLoading;
        useEffect(() => {
            if (mode === "online" && hasNoData) {
                setPagination(prev => {
                    if (prev.pageIndex > 0) {
                        return {...prev, pageIndex: prev.pageIndex - 1};
                    }
                    return prev;
                });
            }
        }, [mode, hasNoData]);

        return (
            <VStack space={[2, 2, 4]}>
                {(filterColumns.length || enableGlobalFilter) && (
                    <View
                        flexDirection={["column-reverse", "row"]}
                        px={[5, 5, 0]}
                        justifyContent="space-between"
                        zIndex="auto"
                        flexWrap="wrap"
                        style={{gap: 12}}
                    >
                        {filterColumns.length > 0 && (
                            <View
                                display="flex"
                                maxW="full"
                                flexDir={["column", "row"]}
                                flexWrap="wrap"
                                style={{gap: 12}}
                            >
                                {filterColumns.map(column => {
                                    const filter = column.columnDef?.filter;
                                    if (filter?.type === "select") {
                                        return (
                                            <FilterSelect
                                                key={column.id}
                                                column={column}
                                                table={table}
                                                name={filter.name}
                                                style={filter.style}
                                                isMulti={filter.isMulti}
                                                isOptionDisabled={filter.isOptionDisabled}
                                            />
                                        );
                                    }
                                    if (filter?.type === "text") {
                                        return (
                                            <FilterText
                                                key={column.id}
                                                kind="column"
                                                column={column}
                                                table={table}
                                                placeholder={filter.placeholder}
                                                style={filter.style}
                                            />
                                        );
                                    }
                                    if (filter?.type === "dateRange") {
                                        return (
                                            <FilterDateRange
                                                key={column.id}
                                                column={column}
                                                table={table}
                                                style={filter.style}
                                                defaultStartDate={filter.defaultStartDate}
                                                defaultEndDate={filter.defaultEndDate}
                                            />
                                        );
                                    }
                                    return null;
                                })}
                            </View>
                        )}
                        <Stack direction={["column", "row"]} space={3}>
                            {enableGlobalFilter && <FilterText kind="table" table={table} />}
                            {exportData && (
                                <Button
                                    colorScheme="blueGray"
                                    variant="outline"
                                    isLoading={exportLoading}
                                    isLoadingText="Exporting"
                                    leftIcon={<ArrowDownIcon />}
                                    onPress={handleExportData}
                                >
                                    Export
                                </Button>
                            )}
                            {enableVisibility && (
                                <EditColumns
                                    columnVisibility={columnVisibility}
                                    columns={table.getAllColumns()}
                                />
                            )}
                        </Stack>
                    </View>
                )}

                {isEmpty ? (
                    <EmptyState />
                ) : (
                    <Table testID={testID} gridTemplateColumns={gridTemplateColumns}>
                        {variant === "table" &&
                            table.getHeaderGroups().map(headerGroup => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map(header => {
                                        if (
                                            isMobile &&
                                            header.column.columnDef.responsive === "md"
                                        ) {
                                            return null;
                                        }
                                        return (
                                            <TableHeader
                                                key={header.id}
                                                {...(header.column.getCanSort() && {
                                                    onPress: header.column.getToggleSortingHandler()
                                                })}
                                                sortDir={header.column.getIsSorted()}
                                                align={header.column.columnDef.align}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                            </TableHeader>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        {table.getRowModel().rows.map(row => (
                            <TableRow key={row.id} row={row} onRowClick={onRowClick}>
                                {row.getVisibleCells().map(cell => {
                                    if (isMobile && cell.column.columnDef.responsive === "md") {
                                        return null;
                                    }
                                    return (
                                        <TableCell
                                            key={cell.id}
                                            align={cell.column.columnDef.align}
                                            verticalAlign={cell.column.columnDef.verticalAlign}
                                            visibleOnHover={cell.column.columnDef.visibleOnHover}
                                            {...(typeof cell.column.columnDef.styleCell ===
                                            "function"
                                                ? cell.column.columnDef.styleCell(row.original)
                                                : {})}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </Table>
                )}

                {((mode === "online" && totalPages) || mode === "offline") &&
                    !isEmpty &&
                    table.getPageCount() > 1 && (
                        <HStack justifyContent={["center", "end"]}>
                            <TablePagination
                                testID="table-pagination"
                                currentPage={table.getState().pagination.pageIndex + 1}
                                totalPages={table.getPageCount()}
                                hasNextPage={table.getCanNextPage()}
                                hasPrevPage={table.getCanPreviousPage()}
                                onNext={table.nextPage}
                                onPrevious={table.previousPage}
                                onChangePage={table.setPageIndex}
                            />
                        </HStack>
                    )}
            </VStack>
        );
    }
) as {
    // this is sort of hack to allow pass our generic for `forwardRef`
    <T extends {}>(p: Props<T> & {ref?: Ref<RefValue<T>>}): React.ReactElement;
    displayName: string;
};

TableFetch.displayName = "forwardRef(TableFetch)";

export default memoWithType(TableFetch);
