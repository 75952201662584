import "@/client/css/global.scss";
import "rc-tooltip/assets/bootstrap.css";
import "@/client/css/nativebase.scss";
import "@/client/css/tooltip.scss";

import {FrontendProvider, ToastSetter, ToastProvider, useWindowSize} from "@hosttools/frontend";
import {ErrorBoundary} from "@sentry/react";
import {RootThemeProvider} from "css-vars-hook";
import FontFaceObserver from "fontfaceobserver";
import {NativeBaseProvider, View} from "native-base";
import type {FC} from "react";
import React, {lazy, memo, Suspense, useMemo, useState, useEffect} from "react";
import FullStory from "react-fullstory";
import LinearGradient from "react-native-web-linear-gradient";
import {BrowserRouter, Redirect, Switch} from "react-router-dom";

import Loader from "./components/Loader";
import MessagesNotification from "./components/MessagesNotification";
import {INBOX_PATH, MESSAGING_PATH, MULTICALENDAR_PATH, PRICING_PATH} from "./constant";
import Error from "./Error";
import PrivateRoute from "./PrivateRoute";
import ErrorProvider from "./providers/ErrorProvider";
import PublicRoute from "./PublicRoute";
import theme from "./theme/theme";

import DetectScreenSize from "@/client/components/DetectScreenSize";
import AddListing from "@/client/features/AddListing";
import ConnectListing from "@/client/features/ConnectListing";
import {AppProvider} from "@/client/provider/AppProvider";
import CSSVarsProvider from "@/client/provider/CSSVarsProvider";

const ORG_ID = __IS_BETA__ ? "o-1HJ52A-na1" : "o-1HC3P9-na1";

const emptyObject = {};

const MultiCalendar = lazy(() => import("./components/MultiCalendar"));
const Turnovers = lazy(() => import("./components/Turnovers"));
const Billing = lazy(() => import("./components/Billing"));
const Messaging = lazy(() => import("./components/Messaging"));
const Pricing = lazy(() => import("./components/Pricing"));
const Settings = lazy(() => import("./components/Settings"));
const Inbox = lazy(() => import("./components/Inbox"));
const Register = lazy(() => import("../client/components/Register"));
const Forgot = lazy(() => import("../client/components/Forgot"));
const Login = lazy(() => import("../client/components/Login"));
const Reset = lazy(() => import("../client/components/Reset"));
const Reason = lazy(() => import("./components/Reason"));
const QuickGuide = lazy(() => import("../client/features/QuickGuide"));
const ListingContent = lazy(() => import("../client/features/ListingContent"));

const Root: FC = () => {
    const size = useWindowSize({
        delay: 350
    });

    return (
        <View
            testID="root-container"
            // this requires to work on mobile browsers especially Safari on iOS
            // which doesn't work great with `100vh` due to its toolbar
            // but it appears to work with `window.innerHeight`
            height={size ? `${size.height}px` : "100vh"}
            overflowX="hidden"
            overflowY="auto"
        >
            <Suspense fallback={<Loader center />}>
                <Switch>
                    <PrivateRoute exact path={MULTICALENDAR_PATH} component={MultiCalendar} />
                    <PrivateRoute
                        path={INBOX_PATH}
                        component={Inbox}
                        permission={useMemo(() => ["inbox", "edit"], [])}
                    />
                    <PrivateRoute
                        path={MESSAGING_PATH}
                        component={Messaging}
                        permission={useMemo(() => ["messageRules", "edit"], [])}
                    />
                    <PrivateRoute
                        path={PRICING_PATH}
                        component={Pricing}
                        permission={useMemo(() => ["pricing", "edit"], [])}
                    />
                    <PrivateRoute path="/settings" component={Settings} />
                    <PrivateRoute
                        path="/billing"
                        component={Billing}
                        permission={useMemo(() => ["billing", "edit"], [])}
                    />
                    <PrivateRoute path="/quick-guide" component={QuickGuide} />
                    <PrivateRoute
                        path="/connect/:listingID/:accountType"
                        component={ConnectListing}
                    />
                    <PrivateRoute path="/add-listing" component={AddListing} />
                    <PrivateRoute path="/listing/:listingID" component={ListingContent} />

                    <PublicRoute path="/login" redirect component={Login} />
                    <PublicRoute path="/register" redirect component={Register} />
                    <PublicRoute path="/forgot" component={Forgot} />
                    <PublicRoute path="/reset/:resetPasswordToken" component={Reset} />
                    <PublicRoute path="/reason/:id" component={Reason} />
                    <PublicRoute
                        path="/turnovers/:accessCode"
                        component={Turnovers}
                        enableReactQuery
                    />
                    <Redirect to="/" />
                </Switch>
            </Suspense>
            <MessagesNotification />
        </View>
    );
};

const config = {
    dependencies: {
        "linear-gradient": LinearGradient
    }
};
const App: FC = () => {
    const [fontLoaded, setFontLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            const font = new FontFaceObserver("InterDisplay", {
                // keep in mind, this must be 500 which is the `medium` being set on `Text` component
                weight: 500
            });
            try {
                await font.load();
                setFontLoaded(true);
            } catch {
                setFontLoaded(true);
            }
        })();
    }, []);

    if (!fontLoaded) {
        // we just simply allow blank screen until font is loaded
        return null;
    }

    return (
        <ErrorBoundary fallback={<Error />}>
            <RootThemeProvider theme={emptyObject}>
                {/* this should wrap nativebase otherwise modal/drawer/menu components can't read data from UserContext */}
                <FrontendProvider baseUrl="">
                    <AppProvider>
                        <ToastProvider>
                            <BrowserRouter>
                                <NativeBaseProvider theme={theme} config={config}>
                                    <ErrorProvider>
                                        <CSSVarsProvider>
                                            {!__DEV__ && <FullStory org={ORG_ID} />}
                                            <Root />
                                            <DetectScreenSize />
                                            <ToastSetter />
                                        </CSSVarsProvider>
                                    </ErrorProvider>
                                </NativeBaseProvider>
                            </BrowserRouter>
                        </ToastProvider>
                    </AppProvider>
                </FrontendProvider>
            </RootThemeProvider>
        </ErrorBoundary>
    );
};

export default memo(App);
