import type {
    Listing as ListingModel,
    ListingCalendar as ListingCalendarModel
} from "@hosttools/core/generated/models";
import {getListingName} from "@hosttools/core/shared/utils/utils.listing";
import type {RequiredChannel} from "@hosttools/core/types";

export type ListingCalendar = MongooseModel2Client<ListingCalendarModel>;
export type ListingRaw = MongooseModel2Client<ListingModel>;

// TODO convert to class
export type Listing<T extends keyof ListingRaw["channels"] = keyof ListingRaw["channels"]> =
    RequiredChannel<ListingRaw, T> &
        Omit<ListingRaw, "timeZone"> & {
            name: string;
            airbnbName: string;
            /**
             * @deprecated use `timeZone` instead
             */
            timeZone: string;
            currencySymbol: string;
            airbnbUserID?: string;
            checkInTime: number;
            checkOutTime: number;
            street: string;
        };

export type ListingChannel = keyof ListingRaw["channels"];
export type SelectChannelType = ListingChannel | "all";

export const defaultCheckInHour = 16;
export const defaultCheckOutHour = 11;

export function sanitizeListing(raw: ListingRaw): Listing {
    const listing = {...raw} as Listing;
    listing.airbnbName = listing.airbnbName ?? "";

    if (!listing.timeZone) {
        const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
        listing.timeZone = timeZone;
    }

    listing.name = getListingName(listing);
    listing.currencySymbol = listing.currencySymbol || "";
    listing.checkInTime = listing.airbnbCheckInTime ?? defaultCheckInHour;
    listing.checkOutTime = listing.airbnbCheckOutTime ?? defaultCheckOutHour;
    listing.street = `${listing.streetNumber ?? ""} ${listing.streetName}`.trim();
    listing.channels = listing.channels ?? {};

    return listing;
}

export function sanitizeListings(listings: Listing[]): Listing[] {
    return listings.map(sanitizeListing);
}
