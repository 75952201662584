import {noop} from "@hosttools/core/constant";
import type {IToastService} from "native-base/lib/typescript/components/composites/Toast";
import {createContext} from "react";

export type ToastOptions = {
    placement: "top-right" | "bottom";
    render: (props: {id: string}) => React.ReactNode;
};

export type ToastAPI = {
    show: (options: ToastOptions) => void;
    close: (toastId: string) => void;
    closeAll: () => void;
    success: (title: string) => void;
    info: (title: string) => void;
    warning: (title: string) => void;
    error: (title: string) => void;
};

export type ToastContextType = {
    toast: ToastAPI;
    setToast: (toast: IToastService) => void;
};

const defaultValue: ToastContextType = {
    toast: {
        show: noop,
        close: noop,
        closeAll: noop,
        success: noop,
        info: noop,
        warning: noop,
        error: noop
    },
    setToast: noop
};

const ToastContext = createContext<ToastContextType>(defaultValue);

export default ToastContext;
