import {helpLink, supportEmail, systemStatusLink} from "@hosttools/core/constant";
import {CommentIcon, DashboardIcon, EnvelopeIcon, QuestionIcon} from "@hosttools/frontend";
import {Box, QuestionIcon as QuestionSolidIcon} from "native-base";
import type {ComponentProps} from "react";
import React, {memo, useMemo} from "react";

import ButtonIcon from "@/client/components/ButtonIcon";
import Dropdown, {useDefaultTrigger} from "@/client/components/Dropdown";
import DropdownItemLink from "@/client/components/Dropdown/DropdownItemLink";

type Props = {
    onSupport: () => void;
};

const SupportIcon = (
    <Box
        p={1.5}
        borderRadius="full"
        borderWidth="1"
        borderStyle="solid"
        borderColor="blueGray.200"
        bgColor="blueGray.50"
    >
        <ButtonIcon icon={QuestionIcon} tooltip="Support" size="xs" />
    </Box>
);

export const SupportMenu: React.FC<Props> = ({onSupport}) => {
    const menuDisplay = useMemo<ComponentProps<typeof DropdownItemLink>[]>(
        () => [
            {
                Icon: QuestionSolidIcon,
                label: "Help Center",
                link: helpLink,
                type: "external"
            },
            {
                Icon: DashboardIcon,
                label: "Running Status",
                link: systemStatusLink,
                type: "external"
            },
            {
                Icon: CommentIcon,
                label: "Chat with us",
                onPress: onSupport
            },
            {
                Icon: EnvelopeIcon,
                label: "Contact us",
                link: `mailto:${supportEmail}`,
                type: "external"
            }
        ],
        [onSupport]
    );

    return (
        <Dropdown
            minWidth={64}
            trigger={useDefaultTrigger(SupportIcon, false)}
            placement="bottom right"
        >
            {menuDisplay.map(menu => {
                return <DropdownItemLink key={menu.label} size="sm" {...menu} />;
            })}
        </Dropdown>
    );
};

export default memo(SupportMenu);
