import {ArrowTopRightIcon} from "@hosttools/frontend";
import {type Props as IconProps} from "@hosttools/frontend/react/components/Icons/Icon";
import {HStack} from "native-base";
import type {ComponentType} from "react";
import React, {memo, useCallback} from "react";
import {useHistory} from "react-router-dom";

import {DropdownContent, DropdownItem} from ".";

type Internal = {
    type: "internal";
    link: string;
};

type External = {
    link: string;
    target?: "_blank" | "_self";
    type: "external";
};

type FunctionLink = {
    onPress: () => void;
};

export type Props = {
    label: string;
    Icon: ComponentType<IconProps> | React.ReactElement;
    testID?: string;
    size?: "xs" | "sm";
} & StrictUnion<Internal | External | FunctionLink>;

const DropdownItemLink: React.FC<Props> = ({
    type,
    target,
    link,
    label,
    Icon,
    testID,
    size,
    onPress
}) => {
    const history = useHistory();

    const handleClickItem = useCallback(() => {
        if (type === "internal") {
            history.push(link);
        }

        if (type === "external") {
            window.open(link, target ?? "_blank");
        }

        if (onPress) {
            onPress();
        }
    }, [history, type, link, target, onPress]);

    const MenuContent = (
        <DropdownContent size={size} Icon={Icon}>
            {label}
        </DropdownContent>
    );

    return (
        <DropdownItem testID={testID ?? "dropdown-item-link"} onPress={handleClickItem}>
            {type === "external" ? (
                <HStack width="full" justifyContent="space-between" alignItems="center">
                    {MenuContent}
                    <ArrowTopRightIcon size="xs" color="blueGray.400" />
                </HStack>
            ) : (
                MenuContent
            )}
        </DropdownItem>
    );
};

export default memo(DropdownItemLink);
