import type {AccountType} from "@hosttools/core/constant";
import useChannel from "@hosttools/frontend/react/hooks/useChannel";
import {HStack, VStack} from "native-base";
import React, {memo, useMemo} from "react";

import ChannelLogo from "../ChannelLogo";
import Link from "../Link";
import Paragraph from "../Paragraph";
import Text from "../Text";

export type Props = {
    channel: AccountType;
};

const ChannelHeader: React.FC<Props> = ({channel}) => {
    const {label} = useChannel(channel);

    const {title, subTitle} = useMemo(() => {
        let title = `${label} Account`;
        let subTitle = `Connect your account with ${label} credentials`;

        if (channel === "Airbnb") {
            subTitle = "Connect your Airbnb account to Host Tools.";
        }

        if (channel === "Booking") {
            title = "Booking.com Account";
            subTitle = "Connect Host Tools to Booking.com";
        }

        if (channel === "Houfy") {
            subTitle = "Link your account with your Houfy Access Token";
        }

        if (channel === "Seam") {
            title = "Smart Lock Account";
            subTitle = "Connect your smart lock account to Host Tools.";
        }

        return {
            title,
            subTitle
        };
    }, [channel, label]);

    return (
        <HStack space={4} alignItems="center" justifyContent="flex-start">
            {channel === "Seam" ? (
                <Link
                    type="external"
                    href="https://help.hosttools.com/en/articles/6066284-do-you-support-smart-locks"
                >
                    <ChannelLogo channel={channel} size="3xl" variant="circle" />
                </Link>
            ) : (
                <ChannelLogo channel={channel} size="2xl" variant="circle" />
            )}
            <VStack>
                <Text variant="xl">{title}</Text>
                <Paragraph variant="sm">{subTitle}</Paragraph>
            </VStack>
        </HStack>
    );
};

export default memo(ChannelHeader);
