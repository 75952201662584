import {providerChannels} from "@hosttools/core/constant";
import type {AxiosInstance} from "axios";

import type {ListingChannel} from "../../models/listing";
import type {Price} from "../../models/price";

export interface AirbticsRates {
    date: string;
    available_median_rate: number;
    booked_median_rate: number;
    occupancy_rate_median: number;
    available_rate_25_percentile: number;
    booked_rate_25_percentile: number;
    available_rate_75_percentile: number;
    booked_rate_75_percentile: number;
}
export interface AirbticsData {
    message: {
        radius_used_in_meters: number;
        no_of_sample: number;
        last_snapshot: string;
        current_snapshot: string;
        next_update: string;
        next_365_days_available_booked_rates: AirbticsRates[];
    };
}

export type OverridePriceChannel = ListingChannel | "all";

export type ChannelObjectType = {
    [Property in OverridePriceChannel]?: {
        enabled?: boolean;
        price?: number | string;
        priceFormatted?: string;
        priceOverride?: number | string;
        priceOverrideFormatted?: string;
        isEditting?: boolean;
    };
};

export interface OverridePrices extends ChannelObjectType {
    isOverridePrices: boolean;
    wasOverridePrices?: boolean;
    priceOverride?: string | null;
}

class PricingService {
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async getPrices(listingID: string, starting: "today" | "yesterday" | "lastMonth" | "lastYear") {
        const url = `/getPrices/${listingID}/${starting}`;
        const {data} = await this.http.get<Price[]>(url);

        return data;
    }

    async getAirbticsData(listingID: string) {
        const url = `/getAirbtics/${listingID}`;
        const {data} = await this.http.get<AirbticsData>(url);

        return data;
    }

    async setPriceOverride(
        startDate: string,
        endDate: string,
        listingID: string,
        prices: OverridePrices
    ) {
        const url = "/setPriceOverride";
        const allChannelPriceOverride = prices.all?.priceOverride;
        const pricesObjectKeys = Object.keys(prices);
        const allOtherChannelsSamePriceWithAllChannelPrice = pricesObjectKeys.every(channel => {
            if (providerChannels.includes(channel)) {
                const channelPrice = prices[channel];
                return channelPrice?.priceOverride === allChannelPriceOverride;
            }
            return true;
        });

        const hasNoAnyPriceOverride = pricesObjectKeys.every(channel => {
            if (providerChannels.includes(channel)) {
                return !prices[channel]?.priceOverride;
            }
            return true;
        });

        const newPrices: {[key in OverridePriceChannel]?: {price?: string | number}} = {};

        if (allChannelPriceOverride && allOtherChannelsSamePriceWithAllChannelPrice) {
            newPrices.all = {price: allChannelPriceOverride};
        } else if (!hasNoAnyPriceOverride) {
            pricesObjectKeys.forEach(channel => {
                if (providerChannels.includes(channel)) {
                    const channelPrice = prices[channel];
                    newPrices[channel] = {
                        price: channelPrice?.priceOverride || channelPrice?.price
                    };
                }
            });
        }

        const {data} = await this.http.post(url, {
            startDate,
            endDate,
            listingID,
            prices: newPrices
        });

        return data;
    }
}

export default PricingService;
