import {useListingService, useToast} from "@hosttools/frontend";
import {type AccountType} from "@hosttools/frontend/models/account";
import useChannel from "@hosttools/frontend/react/hooks/useChannel";
import {AddIcon, ArrowBackIcon, Button, HStack, VStack} from "native-base";
import React, {memo, useCallback, useState, type FC} from "react";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";

import TableAccountListings from "../TableAccountListings";

import ModalAddAccount from "@/admin/components/ModalAddAccount";
import EmptyState from "@/client/components/EmptyState";
import Heading from "@/client/components/Heading";
import Paragraph from "@/client/components/Paragraph";
import useAccountsByType from "@/client/hooks/useAccountsByType";

interface Props {
    // each type is associated with path (step)
    type: "importListings" | "connectChannels";
    parentPath: string;
    goBack?: () => void;
}

const ImportListingsStep: FC<Props> = ({type, parentPath, goBack}) => {
    const toast = useToast();
    const {accountType} = useParams<{accountType: AccountType}>();
    const history = useHistory();
    const {url} = useRouteMatch();

    const [addAccountType, setAddAccountType] = useState<AccountType>();
    const [accountListings, setAccountListings] = useState<Map<string, string[]>>(new Map());

    const handleSelectListings = useCallback((ids: string[], accountID: string) => {
        setAccountListings(prev => {
            const next = new Map([...prev]);
            if (ids.length) {
                next.set(accountID, ids);
            } else {
                next.delete(accountID);
            }
            return next;
        });
    }, []);

    const [spinner, setSpinner] = useState(false);
    const listingService = useListingService();
    const handleImportListings = useCallback(async () => {
        try {
            setSpinner(true);
            await Promise.all(
                [...accountListings].flatMap(async ([accountID, listingIDs]) => {
                    if (listingIDs.length) {
                        await listingService.importListings(accountID, listingIDs);
                    }
                })
            );
            history.push(`${parentPath}/connect-channels`);
        } catch {
            toast.error("Failed to import the listings");
            setSpinner(false);
        }
    }, [accountListings, history, parentPath, listingService, toast]);

    const handleGoBack = useCallback(() => {
        if (goBack) {
            goBack();
        }
        if (type === "connectChannels") {
            history.push(`${parentPath}`);
        } else {
            history.push("/add-listing");
        }
    }, [type, parentPath, history, goBack]);

    const goHome = useCallback(() => {
        history.push("/");
    }, [history]);

    const hideAddAccountModal = useCallback(() => {
        setAddAccountType(undefined);
    }, []);

    const handleAddAdditionalAccount = useCallback(() => {
        setAddAccountType(accountType);
    }, [accountType]);

    const accounts = useAccountsByType(accountType);
    const {label} = useChannel(accountType);

    let title = {
        heading: "Import listings",
        description: accounts.length ? (
            <Paragraph variant="lg">
                We&apos;ve found these listings on {label}. Select the listings you want to import
                into Host Tools
            </Paragraph>
        ) : null
    };
    if (type === "connectChannels") {
        title = {
            heading: "Connect additional channels",
            description: (
                <Paragraph variant="lg">
                    Are your listings listed on other channels? Lets connect additional channels
                    here
                </Paragraph>
            )
        };
    }

    return (
        <>
            <VStack space={6}>
                <VStack space={2}>
                    <Heading variant="2xl">{title.heading}</Heading>
                    {title.description}
                </VStack>

                {accounts.map(elem =>
                    type === "importListings" ? (
                        <TableAccountListings
                            key={elem._id}
                            type={type}
                            accountID={elem._id}
                            onSelectListings={handleSelectListings}
                        />
                    ) : (
                        <TableAccountListings key={elem._id} type={type} accountID={elem._id} />
                    )
                )}

                {accounts.length > 0 && type === "importListings" && (
                    <Button
                        mt={4}
                        variant="outline"
                        leftIcon={<AddIcon />}
                        onPress={handleAddAdditionalAccount}
                    >
                        Connect additional account
                    </Button>
                )}
            </VStack>

            {!accounts.length && (
                <EmptyState primaryText={`Let's connect your first ${label} account`}>
                    <Button
                        mt={4}
                        leftIcon={<AddIcon />}
                        testID="btn-connect-account"
                        onPress={handleAddAdditionalAccount}
                    >
                        Connect account
                    </Button>
                </EmptyState>
            )}

            <HStack alignSelf="flex-end" space={2} mt={4}>
                {type === "importListings" ? (
                    <>
                        <Button
                            variant="outline"
                            leftIcon={<ArrowBackIcon />}
                            onPress={handleGoBack}
                        >
                            Back
                        </Button>
                        {accounts.length > 0 && (
                            <Button
                                testID="btn-import-listings"
                                isDisabled={accountListings.size < 1 || spinner}
                                isLoading={spinner}
                                isLoadingText="Importing..."
                                onPress={handleImportListings}
                            >
                                Import
                            </Button>
                        )}
                    </>
                ) : (
                    <Button onPress={goHome}>Done</Button>
                )}
            </HStack>

            {addAccountType && (
                <ModalAddAccount
                    show
                    oAuthReturnUrl={
                        typeof ONBOARDING_OAUTH_RETURN_URL !== "undefined"
                            ? ONBOARDING_OAUTH_RETURN_URL
                            : `${window.location.origin}/add-listing`
                    }
                    oAuthStateParameter={url}
                    channel={addAccountType}
                    onHide={hideAddAccountModal}
                    onConnected={hideAddAccountModal}
                />
            )}
        </>
    );
};

export default memo(ImportListingsStep);
