import type {PermissionKey, PermissionValue} from "@hosttools/core/models/user";
import {HTTPContext, UserContext, usePrevious, useToast} from "@hosttools/frontend";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import axios from "axios";
import React, {useContext, memo, useCallback, useRef, useEffect} from "react";
import type {FC, ComponentType} from "react";
import {Redirect, Route} from "react-router-dom";
import type {RouteProps, RouteComponentProps} from "react-router-dom";

import Footer from "./components/Footer";

import Errors from "@/admin/components/Errors/Errors";
import Header from "@/admin/components/Header";
import Loader from "@/admin/components/Loader";
import ExternalServices from "@/client/components/ExternalServices";
import ModalConfirm from "@/client/components/ModalConfirm";
import useBuildAxiosError from "@/client/hooks/useBuildAxiosError";
import {AppContext} from "@/client/provider/AppProvider";

interface Props extends RouteProps {
    permission?: [PermissionKey, PermissionValue];
    component: ComponentType<RouteComponentProps>;
}

const queryClient = new QueryClient();

const PrivateRoute: FC<Props> = ({component: Component, permission, ...rest}) => {
    const {confirmModal} = useContext(AppContext);
    const toast = useToast();
    const {isAuthenticated, isLoading, permissions, isLoadingAccounts, listings, setState} =
        useContext(UserContext);

    // keep track if user has just loaded the page
    const isJustAuthenticatedRef = useRef<boolean>();
    const lastAuthenticated = usePrevious(isAuthenticated);
    useEffect(() => {
        if (!lastAuthenticated && isAuthenticated) {
            isJustAuthenticatedRef.current = true;
        } else {
            isJustAuthenticatedRef.current = false;
        }
    }, [isAuthenticated, lastAuthenticated]);

    const http = useContext(HTTPContext);
    const interceptorRef = useRef<number>();
    const buildError = useBuildAxiosError();

    useEffect(() => {
        if (isAuthenticated) {
            if (typeof interceptorRef.current === "number") {
                http.interceptors.response.eject(interceptorRef.current);
            }
            const interceptor = http.interceptors.response.use(
                // do nothing with response
                response => response,
                error => {
                    if (axios.isAxiosError(error)) {
                        // take user to login page
                        if (error.response?.status === 403) {
                            if (interceptorRef.current) {
                                http.interceptors.response.eject(interceptorRef.current);
                            }
                            setState(prev => ({
                                ...prev,
                                isAuthenticated: false
                            }));
                            toast.error(
                                buildError(error, "Your session was expired. Please login again")
                            );
                            return;
                        }
                        // probably add the blacklist
                        toast.error(
                            buildError(error, "There was an issue occurred. Please try again")
                        );
                    }
                    throw error;
                }
            );
            interceptorRef.current = interceptor;
        }
    }, [http, isAuthenticated, buildError, setState, toast]);

    const handleRender = useCallback(
        (props: RouteComponentProps) => {
            if (isAuthenticated === true && !isLoading && !isLoadingAccounts) {
                const isOnboarding = listings.length === 0;
                const mainElem = (
                    <>
                        {!isOnboarding && <Header />}
                        {!isOnboarding && <Errors />}
                        <Component {...props} />
                        <Footer />
                        <ExternalServices />
                    </>
                );

                if (permission) {
                    const permissionKey = permission[0];
                    const permissionValue = permission[1];
                    if (permissions[permissionKey]?.[permissionValue]) {
                        return mainElem;
                    }
                    // no permission takes user to home page
                    return <Redirect to={{pathname: "/"}} />;
                }

                // check search redirected from login
                const ps = new URLSearchParams(props.location.search);

                // don't allow user go anywhere if they have no listings
                // force user to add at least 1 listing
                const sources = ["login", "signup"];
                if (isJustAuthenticatedRef.current || sources.includes(ps.get("source"))) {
                    const addListingPath = "/add-listing";
                    if (isOnboarding && props.location.pathname !== addListingPath) {
                        return <Redirect to={{pathname: addListingPath}} />;
                    }
                }

                return mainElem;
            }
            if (!isAuthenticated && !isLoading) {
                return <Redirect to={{pathname: "/login"}} />;
            }

            return <Loader center />;
        },
        [
            permission,
            Component,
            isLoading,
            permissions,
            isAuthenticated,
            isLoadingAccounts,
            listings
        ]
    );

    return (
        <QueryClientProvider client={queryClient}>
            <Route {...rest} render={handleRender} />
            {confirmModal && <ModalConfirm {...confirmModal} />}
        </QueryClientProvider>
    );
};

export default memo(PrivateRoute);
