import {isReactElement} from "@hosttools/frontend";
import {
    Box,
    Button,
    CloseIcon,
    HStack,
    Modal as ModalNB,
    Pressable,
    VStack,
    type IButtonProps,
    type IModalProps
} from "native-base";
import React, {Fragment, memo} from "react";

import Drawer from "../Drawer";
import DrawerLayout from "../Drawer/DrawerLayout";
import Heading from "../Heading";
import Paragraph from "../Paragraph";
import Tooltip from "../Tooltip";

import useMobileView from "@/client/hooks/useMobileView";

export type Props = {
    isOpen: boolean;
    children?: React.ReactNode;
    title: string;
    subTitle?: React.ReactNode;
    footer?: React.ReactNode;
    isLoading?: boolean;
    size?: IModalProps["size"];
    btnTextCancel?: string;
    btnTextOk?: string;
    btnTextReset?: string;
    btnColorScheme?: IButtonProps["colorScheme"];
    responsive?: boolean;
    onClose?: () => void;
    onSubmit?: () => void;
    onReset?: () => void;
};

const Modal: React.FC<Props> = ({
    isOpen,
    children,
    footer,
    title,
    subTitle,
    isLoading,
    size: sizeProp,
    btnTextCancel = "Cancel",
    btnTextOk = "Save",
    btnTextReset = "Reset",
    btnColorScheme,
    responsive = true,
    onClose,
    onSubmit,
    onReset
}) => {
    const isMobile = useMobileView();

    const hasFooter = !!footer || typeof onSubmit === "function" || typeof onReset === "function";

    if (isMobile && responsive) {
        return (
            <Drawer isOpen={isOpen} fullSize placement="bottom" onClose={onClose}>
                <DrawerLayout title={subTitle ? undefined : title} onHide={onClose}>
                    <>
                        {onSubmit && (
                            <Button
                                variant="ghost"
                                minW="auto"
                                isLoading={isLoading}
                                colorScheme={btnColorScheme}
                                onPress={onSubmit}
                            >
                                {btnTextOk}
                            </Button>
                        )}
                    </>
                    <>
                        {!!subTitle && (
                            <VStack space={2} px={5}>
                                <Heading variant="lg" color="blueGray.800">
                                    {title}
                                </Heading>
                                {typeof subTitle === "string" ||
                                isReactElement(subTitle, Fragment) ? (
                                    <Paragraph variant="sm">{subTitle}</Paragraph>
                                ) : (
                                    subTitle
                                )}
                            </VStack>
                        )}
                        {children}
                    </>
                </DrawerLayout>
                {footer && (
                    <Box
                        flexShrink={0}
                        flexGrow={0}
                        px={5}
                        py={4}
                        borderTopLeftRadius={12}
                        borderTopRightRadius={12}
                        bgColor="white"
                        width="full"
                    >
                        {footer}
                    </Box>
                )}
            </Drawer>
        );
    }

    const size = sizeProp ?? (isMobile ? "xl" : "md");

    return (
        <ModalNB
            testID="modal"
            isOpen={isOpen}
            size={size}
            onClose={onClose}
            // _overlay={overlay}
        >
            <ModalNB.Content
                testID="modal-content"
                backgroundColor="white"
                borderRadius={20}
                maxH="min(769px, 95%)"
            >
                {title && (
                    <ModalNB.Header
                        backgroundColor="white"
                        px={5}
                        pb={4}
                        pt={hasFooter ? 4 : 5}
                        borderBottomWidth={0}
                    >
                        <VStack space={1}>
                            <HStack space={4} alignItems="center" justifyContent="space-between">
                                <Heading variant="lg">{title}</Heading>
                                {onClose && (
                                    <Tooltip label="Close">
                                        <Pressable testID="btn-close-modal" onPress={onClose}>
                                            <CloseIcon color="blueGray.400" />
                                        </Pressable>
                                    </Tooltip>
                                )}
                            </HStack>
                            {subTitle && (
                                <>
                                    {typeof subTitle === "string" ||
                                    isReactElement(subTitle, Fragment) ? (
                                        <Paragraph variant="sm">{subTitle}</Paragraph>
                                    ) : (
                                        subTitle
                                    )}
                                </>
                            )}
                        </VStack>
                    </ModalNB.Header>
                )}
                {children && (
                    <ModalNB.Body testID="modal-body" px={5} pt={0} pb={footer ? 4 : 5}>
                        {children}
                    </ModalNB.Body>
                )}

                {footer ? (
                    <ModalNB.Footer backgroundColor="white" px={5} py={4} mt={children ? 2 : 0}>
                        {footer}
                    </ModalNB.Footer>
                ) : (
                    (typeof onSubmit === "function" || typeof onReset === "function") && (
                        <ModalNB.Footer
                            backgroundColor="white"
                            px={5}
                            py={4}
                            mt={children ? 2 : 0}
                            justifyContent="space-between"
                        >
                            {onReset && (
                                <Button variant="ghost" colorScheme="danger" onPress={onReset}>
                                    {btnTextReset}
                                </Button>
                            )}
                            <HStack ml="auto">
                                {onClose && (
                                    <Button
                                        variant="outline"
                                        colorScheme="blueGray"
                                        aria-label={btnTextCancel}
                                        onPress={onClose}
                                        ml={2}
                                    >
                                        {btnTextCancel}
                                    </Button>
                                )}
                                {onSubmit && (
                                    <Button
                                        testID="btn-submit"
                                        aria-label={btnTextOk}
                                        ml={typeof onClose === "function" ? 2 : undefined}
                                        isLoading={isLoading}
                                        colorScheme={btnColorScheme}
                                        onPress={onSubmit}
                                    >
                                        {btnTextOk}
                                    </Button>
                                )}
                            </HStack>
                        </ModalNB.Footer>
                    )
                )}
            </ModalNB.Content>
        </ModalNB>
    );
};

export default memo(Modal);
