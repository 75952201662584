import type {useToast} from "native-base";
import type {FC} from "react";
import React, {useCallback, useMemo, useState} from "react";

import ToastAlert from "../components/ToastAlert";
import type {ToastOptions} from "../contexts/ToastContext";
import ToastContext from "../contexts/ToastContext";
import {useLatest} from "../hooks/useLatest";

const ToastProvider: FC = ({children}) => {
    const [toast, setToast] = useState<ReturnType<typeof useToast>>();

    const toastRef = useLatest(toast);

    const getToast = useCallback(() => {
        if (!toastRef.current) {
            console.warn("Toast is called before it has been set");
            return;
        }
        return toastRef.current;
    }, [toastRef]);

    const show = useCallback(
        (options: ToastOptions) => {
            const toast = getToast();
            toast?.show(options);
        },
        [getToast]
    );

    const close = useCallback(
        (toastId: string) => {
            const toast = getToast();
            toast?.close(toastId);
        },
        [getToast]
    );

    const closeAll = useCallback(() => {
        const toast = getToast();
        toast?.closeAll();
    }, [getToast]);

    const success = useCallback(
        (title: string) =>
            show({
                placement: "bottom",
                render: () => {
                    return <ToastAlert title={title} status="success" />;
                }
            }),
        [show]
    );
    const info = useCallback(
        (title: string) =>
            show({
                placement: "bottom",
                render: () => {
                    return <ToastAlert title={title} status="info" />;
                }
            }),
        [show]
    );
    const warning = useCallback(
        (title: string) =>
            show({
                placement: "bottom",
                render: () => {
                    return <ToastAlert title={title} status="warning" />;
                }
            }),
        [show]
    );
    const error = useCallback(
        (title: string) =>
            show({
                placement: "bottom",
                render: () => {
                    return <ToastAlert title={title} status="error" />;
                }
            }),
        [show]
    );

    return (
        <ToastContext.Provider
            value={useMemo(
                () => ({
                    toast: {
                        show,
                        close,
                        closeAll,
                        success,
                        info,
                        warning,
                        error
                    },
                    setToast
                }),
                [show, close, closeAll, success, info, warning, error]
            )}
        >
            {children}
        </ToastContext.Provider>
    );
};

export default ToastProvider;
