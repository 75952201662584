import "react-day-picker/dist/style.css";
import "./dateRangePicker.scss";

import {useDisclose, useLatest} from "@hosttools/frontend";
import {CalendarIcon, PencilIcon} from "@hosttools/frontend/react/components/Icons";
import shadows from "@hosttools/frontend/theme/shadows";
import classNames from "classnames";
import {
    addDays,
    addMonths,
    addWeeks,
    addYears,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    isAfter,
    isBefore,
    isFirstDayOfMonth,
    isLastDayOfMonth,
    isSameDay,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear
} from "date-fns";
import {
    Box,
    Button,
    Center,
    ChevronDownIcon,
    Divider,
    HStack,
    Popover,
    Pressable,
    VStack
} from "native-base";
import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from "react";
import type {DateAfter, DateBefore, DateInterval} from "react-day-picker";
import {DayPicker} from "react-day-picker";
import type {StyleProp, ViewStyle} from "react-native";

import ButtonIcon from "../ButtonIcon";
import Drawer from "../Drawer";
import FormField from "../FormField";
import Input from "../Input";
import Text from "../Text";

import DateTime from "./DateTime";
import QuickSelectDateButton from "./QuickSelectDateButton";
import type {QuickSelectDate} from "./type";

import {formatMMddyyyy} from "@/admin/utils/date";
import useMobileView from "@/client/hooks/useMobileView";

export type OptionalDate = Date | undefined;

export type Variant = "form" | "filter" | "edit";

type Errors = {
    startDate?: string;
    endDate?: string;
};

export type Props = {
    startDate?: OptionalDate;
    startDateLabel?: string;
    startDatePlaceholder?: string;
    endDate?: OptionalDate;
    endDateLabel?: string;
    endDatePlaceholder?: string;
    disabledDates?: Array<DateInterval | DateBefore | DateAfter>;
    allowQuickSelectDate?: boolean;
    defaultOpen?: boolean;
    variant?: Variant;
    allowSameDay?: boolean;
    errors?: Errors;
    onSelectedDates: (startDate: OptionalDate, endDate: OptionalDate, valid?: boolean) => void;
};

const formatDateRange = (startDate: Date, endDate: Date) => {
    let dateRange = "";
    if (format(startDate, "MMM") === format(endDate, "MMM")) {
        dateRange = `${format(startDate, "MMM d")} - ${format(endDate, "d, y")}`;
    } else {
        dateRange = `${format(startDate, "MMM d")} - ${format(endDate, "MMM d, y")}`;
    }

    return dateRange;
};

function isSelectingFirstDay(day: Date, startDate?: OptionalDate, endDate?: OptionalDate) {
    const isBeforeFirstDay = startDate && isBefore(day, startDate);
    const isRangeSelected = startDate && endDate;
    return !startDate || isBeforeFirstDay || isRangeSelected;
}

const weekends = {
    sunday: {dayOfWeek: [0]},
    saturday: {dayOfWeek: [6]}
};

const months = {
    firstOfMonth: isFirstDayOfMonth,
    lastOfMonth: isLastDayOfMonth
};

const formatWeekdayName = (date: Date) => format(date, "eeeee");

const DAY_WIDTH_MOBILE = 48;

const DateRangePicker: React.FC<Props> = ({
    startDate: defaultStartDate,
    startDateLabel = "Start Date",
    startDatePlaceholder = "Select start date",
    endDate: defaultEndDate,
    endDateLabel = "End Date",
    endDatePlaceholder = "Select end date",
    disabledDates = [],
    allowQuickSelectDate,
    defaultOpen = false,
    variant = "form",
    allowSameDay,
    errors,
    onSelectedDates
}) => {
    const isMobile = useMobileView();
    const today = useMemo(() => new Date(), []);
    const [startDate, setStartDate] = useState<OptionalDate>(defaultStartDate);
    const [endDate, setEndDate] = useState<OptionalDate>(defaultEndDate);
    const [month, setMonth] = useState<OptionalDate>(defaultStartDate || today);
    const {isOpen, onClose, onOpen} = useDisclose(defaultOpen);
    const [enteredTo, setEnteredTo] = useState<OptionalDate>(undefined);
    const alreadySelectStartDate = useRef(false);
    const startDateRef = useLatest(startDate);

    const disabledDays = useMemo(
        () => (disabledDates.length ? disabledDates : undefined),
        [disabledDates]
    );

    const selectedDays = useMemo(
        () => ({from: startDate, to: enteredTo || endDate}),
        [endDate, enteredTo, startDate]
    );

    const modifiers = useMemo(
        () => ({
            ...(selectedDays.from ? {start: selectedDays.from} : {}),
            ...(selectedDays.to ? {end: selectedDays.to} : {}),
            ...weekends,
            ...months
        }),
        [selectedDays.from, selectedDays.to]
    );

    const isValidRange = useCallback(
        (start: Date, end: Date) => {
            const valid = disabledDates.every(range => {
                const startsBeforeRange = "after" in range ? isBefore(start, range.after) : false;
                const endsBeforeRange =
                    "after" in range
                        ? isBefore(end, range.after) || isSameDay(end, range.after)
                        : false;
                let startsAfterRange =
                    "before" in range
                        ? isAfter(start, range.before) || isSameDay(start, range.before)
                        : false;
                let endsAfterRange = "before" in range ? isAfter(end, range.before) : false;
                if (!("before" in range)) {
                    startsAfterRange = isAfter(start, range.after);
                    endsAfterRange = isAfter(end, range.after);
                }
                const startsAndEndsBeforeRange = startsBeforeRange && endsBeforeRange;
                const startsAndEndsAfterRange = startsAfterRange && endsAfterRange;
                return startsAndEndsBeforeRange || startsAndEndsAfterRange;
            });
            return valid;
        },
        [disabledDates]
    );

    const quickSelectDates = useMemo(() => {
        const dates = [
            {name: "Today", startDate: startOfDay(today), endDate: startOfDay(today)},
            {
                name: "Yesterday",
                startDate: startOfDay(addDays(today, -1)),
                endDate: startOfDay(addDays(today, -1))
            },
            {
                name: "This Week",
                startDate: startOfWeek(today),
                endDate: endOfWeek(today)
            },
            {
                name: "Last week",
                startDate: startOfWeek(addWeeks(today, -1)),
                endDate: endOfWeek(addWeeks(today, -1))
            },
            {
                name: "This Month",
                startDate: startOfMonth(today),
                endDate: endOfMonth(today)
            },
            {
                name: "Last Month",
                startDate: startOfMonth(addMonths(today, -1)),
                endDate: endOfMonth(addMonths(today, -1))
            },
            {
                name: "This Year",
                startDate: startOfYear(today),
                endDate: endOfYear(today)
            },
            {
                name: "Last Year",
                startDate: startOfYear(addYears(today, -1)),
                endDate: endOfYear(addYears(today, -1))
            }
        ];
        return dates.filter(date => isValidRange(date.startDate, date.endDate));
    }, [isValidRange, today]);

    const handleDayClick = useCallback(
        (day: Date) => {
            const startDay = startOfDay(day);

            // Cancel selection
            if (
                startDate &&
                endDate &&
                isSameDay(startDay, startDate) &&
                (isSameDay(startDate, endDate) || !allowSameDay)
            ) {
                setStartDate(undefined);
                setEndDate(undefined);
                setMonth(undefined);
                onSelectedDates(undefined, undefined, false);
                return;
            }

            if (
                isSelectingFirstDay(startDay, startDate, endDate) &&
                !alreadySelectStartDate.current
            ) {
                const endDay = endDate && isBefore(startDay, endDate) ? endDate : undefined;
                const valid = isValidRange(startDay, endDay || addDays(startDay, 1));

                setStartDate(valid ? startDay : undefined);
                setEndDate(valid ? endDay : undefined);
                setEnteredTo(undefined);

                onSelectedDates(startDay, endDay, valid);
                alreadySelectStartDate.current = true;
            } else if (startDate && (allowSameDay || !isSameDay(startDate, startDay))) {
                const isDayValid =
                    isBefore(startDate, day) || (allowSameDay && isSameDay(startDate, day));

                const startDay = isDayValid ? startDate : day;
                const endDay = isDayValid ? day : undefined;
                const valid = isValidRange(startDay, endDay || addDays(startDay, 1));

                setStartDate(valid ? startDay : undefined);
                setEndDate(valid ? endDay : undefined);
                setEnteredTo(undefined);

                onSelectedDates(startDay, endDay, valid);
                alreadySelectStartDate.current = false;
            }
        },
        [allowSameDay, endDate, isValidRange, onSelectedDates, startDate]
    );

    const handleQuickSelect = useCallback(
        (day: QuickSelectDate) => {
            setStartDate(day.startDate);
            setEndDate(day.endDate);
            setMonth(day.startDate);
            onSelectedDates(day.startDate, day.endDate, true);
            onClose();
        },
        [onClose, onSelectedDates]
    );

    const handleSelectStartDate = useCallback(() => {
        alreadySelectStartDate.current = false;
        onOpen();
    }, [onOpen]);

    const handleSelectEndDate = useCallback(() => {
        if (startDate) {
            alreadySelectStartDate.current = true;
        }
        onOpen();
    }, [onOpen, startDate]);

    const trigger = useCallback(
        triggerProps => {
            if (variant === "edit") {
                return (
                    <Pressable {...triggerProps}>
                        <ButtonIcon tooltip="Edit date range" icon={PencilIcon} onPress={onOpen} />
                    </Pressable>
                );
            }

            if (variant === "form") {
                return (
                    <HStack {...triggerProps} space={4}>
                        <FormField label={startDateLabel} error={errors?.startDate}>
                            <Input
                                value={startDate ? formatMMddyyyy(startDate) : ""}
                                placeholder={startDatePlaceholder}
                                isReadOnly
                                onFocus={handleSelectStartDate}
                                InputRightElement={<CalendarIcon color="blueGray.400" mr={3} />}
                            />
                        </FormField>
                        <FormField label={endDateLabel} error={errors?.endDate}>
                            <Input
                                value={endDate ? formatMMddyyyy(endDate) : ""}
                                isReadOnly
                                placeholder={endDatePlaceholder}
                                onFocus={handleSelectEndDate}
                                InputRightElement={<CalendarIcon color="blueGray.400" mr={3} />}
                            />
                        </FormField>
                    </HStack>
                );
            }

            return (
                <Pressable {...triggerProps} maxW="100%" onPress={onOpen}>
                    {variant === "filter" && (
                        <HStack
                            space={2}
                            padding={2}
                            borderColor="blueGray.200"
                            borderWidth={1}
                            borderRadius={4}
                            alignItems="center"
                            minHeight="36px"
                            style={
                                isOpen
                                    ? ({
                                          boxShadow: `${shadows.spread.shadowOffset.width}px ${shadows.spread.shadowOffset.height}px ${shadows.spread.shadowRadius}px ${shadows.spread.elevation}px ${shadows.spread.shadowColor}80`
                                      } as StyleProp<ViewStyle>)
                                    : shadows.textField
                            }
                        >
                            <Text variant="sm" color="blueGray.800">
                                {`Date range: ${
                                    startDate && endDate ? formatDateRange(startDate, endDate) : ""
                                }`}
                            </Text>
                            <ChevronDownIcon color="blueGray.800" size="sm" />
                        </HStack>
                    )}
                </Pressable>
            );
        },
        [
            variant,
            isOpen,
            startDate,
            endDate,
            startDateLabel,
            endDateLabel,
            startDatePlaceholder,
            endDatePlaceholder,
            errors?.startDate,
            errors?.endDate,
            onOpen,
            handleSelectStartDate,
            handleSelectEndDate
        ]
    );

    const handleDayMouseEnter = useCallback(
        (day: Date) => {
            const startDay = startOfDay(day);

            if (
                startDate &&
                !isSelectingFirstDay(startDay, startDate, endDate) &&
                isValidRange(startDate, startDay)
            ) {
                setEnteredTo(startDay);
            }
        },
        [endDate, isValidRange, startDate]
    );

    const datePickerClasses = classNames("rdp-date-range", {"rdp-date-range-mobile": isMobile});

    const DayPickerComponent = (
        <DayPicker
            mode="range"
            className={datePickerClasses}
            month={month}
            showOutsideDays
            onMonthChange={setMonth}
            numberOfMonths={isMobile ? 1 : 2}
            selected={selectedDays}
            disabled={disabledDays}
            modifiers={modifiers}
            modifiersClassNames={{
                start: "start",
                end: "end",
                sunday: "sunday",
                saturday: "saturday",
                firstOfMonth: "firstOfMonth",
                lastOfMonth: "lastOfMonth"
            }}
            formatters={{
                formatWeekdayName
            }}
            components={{DayContent: DateTime}}
            onDayClick={handleDayClick}
            onDayMouseEnter={handleDayMouseEnter}
        />
    );

    useEffect(() => {
        if (isOpen && startDateRef.current) {
            setMonth(startDateRef.current);
        }
    }, [isOpen, startDateRef]);

    if (isMobile) {
        const InputElement = trigger({});
        return (
            <>
                {InputElement}
                <Drawer isOpen={isOpen} fullSize placement="bottom" onClose={onClose}>
                    <HStack
                        width="full"
                        px={5}
                        py={5}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Text variant="md">Choose Date</Text>
                        <Button minW="auto" variant="ghost" onPress={onClose}>
                            Done
                        </Button>
                    </HStack>
                    {allowQuickSelectDate && quickSelectDates.length > 0 && (
                        <VStack width="full" space={2} mb={4}>
                            {quickSelectDates.map(day => (
                                <QuickSelectDateButton
                                    key={day.name}
                                    day={day}
                                    onQuickSelectDate={handleQuickSelect}
                                />
                            ))}
                        </VStack>
                    )}
                    <Center px={5}>
                        <Box width={DAY_WIDTH_MOBILE * 7}>{DayPickerComponent}</Box>
                    </Center>
                    <Box
                        py={4}
                        px={5}
                        borderTopWidth="1px"
                        borderTopStyle="solid"
                        borderTopColor="blueGray.200"
                    >
                        <HStack alignItems="center">
                            <Box w="1/2" pr={2}>
                                <Input
                                    value={startDate ? formatMMddyyyy(startDate) : ""}
                                    isReadOnly
                                />
                            </Box>
                            <Box w="1/2" pl={2}>
                                <Input value={endDate ? formatMMddyyyy(endDate) : ""} isReadOnly />
                            </Box>
                        </HStack>
                    </Box>
                </Drawer>
            </>
        );
    }

    return (
        <Popover isOpen={isOpen} onClose={onClose} trigger={trigger}>
            <Popover.Content bgColor="white" borderRadius="xl" p={5}>
                <VStack space={4} divider={<Divider bg="blueGray.100" />}>
                    <HStack
                        space={5}
                        divider={
                            <Divider orientation="vertical" height="unset" bg="blueGray.100" />
                        }
                    >
                        {allowQuickSelectDate && quickSelectDates.length > 0 && (
                            <VStack space={2}>
                                {quickSelectDates.map(day => (
                                    <QuickSelectDateButton
                                        key={day.name}
                                        day={day}
                                        onQuickSelectDate={handleQuickSelect}
                                    />
                                ))}
                            </VStack>
                        )}
                        {DayPickerComponent}
                    </HStack>
                    <HStack justifyContent="space-between" alignItems="center">
                        <HStack space={3} alignItems="center">
                            <Input
                                w={32}
                                value={startDate ? formatMMddyyyy(startDate) : ""}
                                isReadOnly
                            />
                            <Text variant="sm" color="blueGray.400">
                                -
                            </Text>
                            <Input
                                w={32}
                                value={endDate ? formatMMddyyyy(endDate) : ""}
                                isReadOnly
                            />
                        </HStack>
                        <Button variant="outline" width="92px" onPress={onClose}>
                            Close
                        </Button>
                    </HStack>
                </VStack>
            </Popover.Content>
        </Popover>
    );
};

export default memo(DateRangePicker);
