import {useToast} from "native-base";
import type React from "react";
import {memo, useEffect, useContext} from "react";

import ToastContext from "../../contexts/ToastContext";

const ToastSetter: React.FC = () => {
    const toast = useToast();
    const {setToast} = useContext(ToastContext);
    useEffect(() => {
        setToast(toast);
    }, [toast, setToast]);
    return null;
};

export default memo(ToastSetter);
