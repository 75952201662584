import type {AccountType} from "@hosttools/core/constant";
import {useMemo} from "react";

import {buildChannel} from "../../../models/channel";
import type {ChannelMapType, ICalChannelObject, OtherChannelObject} from "../../../models/channel";

function useChannel<T extends AccountType | "internal">(channel: T): ChannelMapType[T];

function useChannel(channel: string, isChannelConnector: boolean): OtherChannelObject;

function useChannel(channel: string): ICalChannelObject;

function useChannel(channel: AccountType | "internal" | (string & {})) {
    return useMemo(() => buildChannel(channel), [channel]);
}

export default useChannel;
