import {
    integrations,
    originalChannels,
    providerChannels,
    type AccountType,
    type ListingStatuses
} from "@hosttools/core/constant";
import {buildChannel} from "@hosttools/frontend/models/channel";
import useChannel from "@hosttools/frontend/react/hooks/useChannel";
import {Center, CloseIcon, Image, View, Pressable, HStack, useTheme} from "native-base";
import React, {memo, useCallback, useMemo} from "react";

import Dot from "../Dot";
import Tooltip from "../Tooltip";

import type {Size} from "./useChannelLogoVariant";
import {useChannelLogoVariant} from "./useChannelLogoVariant";
import useStatusLabel from "./useStatusLabel";

import Text from "@/client/components/Text";

export type Props = {
    size?: Size;
    variant?: "default" | "circle";
    includeLabel?: boolean;
    status?: ListingStatuses;
} & StrictUnion<
    | {
          channel: AccountType | "internal";
          onRemove?: (channel: AccountType) => void;
      }
    | {
          channel: string;
          logoUrl: string;
          onRemove?: (channel: string) => void;
      }
>;

const ChannelLogo: React.FC<Props> = ({
    channel,
    size = "sm",
    variant = "default",
    includeLabel,
    status,
    logoUrl,
    onRemove
}) => {
    const channelConfig = useChannel(channel);
    const {colors} = useTheme();

    const value = useChannelLogoVariant(size);
    const statusLabel = useStatusLabel(status);

    const logo = useMemo(() => {
        if (
            channel === "internal" ||
            providerChannels.includes(channel) ||
            integrations.includes(channel) ||
            channelConfig.colorScheme === "icalendar"
        ) {
            const channelConfig = buildChannel(channel);
            const {LogoCircle} = channelConfig;

            if (variant === "circle" && LogoCircle) {
                return (
                    <LogoCircle
                        data-testid={channel}
                        fill={
                            channelConfig.colorScheme === "icalendar"
                                ? colors[channelConfig.colorScheme][300]
                                : undefined
                        }
                        height={`${value.logoSize}px`}
                        width={`${value.logoSize}px`}
                    />
                );
            }
            const {Logo, label} = channelConfig;
            if (Logo) {
                return (
                    <HStack space={value.space}>
                        <Logo
                            data-testid={channel}
                            fill={
                                channelConfig.colorScheme === "icalendar"
                                    ? colors[channelConfig.colorScheme][300]
                                    : undefined
                            }
                            width={`${value.logoSize}px`}
                            height={`${value.logoSize}px`}
                        />
                        {includeLabel && <Text variant={value.textSize}>{label}</Text>}
                    </HStack>
                );
            }
            return null;
        }

        const imageSource = {uri: logoUrl};

        return (
            <Image
                source={imageSource}
                alt={channel}
                rounded={variant === "circle" ? "full" : undefined}
                width={`${value.logoSize}px`}
                height={`${value.logoSize}px`}
            />
        );
    }, [
        channel,
        includeLabel,
        logoUrl,
        variant,
        channelConfig.colorScheme,
        colors,
        value.logoSize,
        value.space,
        value.textSize
    ]);

    const handleRemove = useCallback(() => {
        if (channel !== "internal" && originalChannels.includes(channel)) {
            onRemove?.(channel);
        }

        if (logoUrl) {
            onRemove?.(channel);
        }
    }, [channel, logoUrl, onRemove]);

    return (
        <View position="relative">
            <Tooltip label={`${channelConfig.label}${statusLabel ? `: ${statusLabel}` : ""}`}>
                {logo}
            </Tooltip>
            {channel !== "internal" && onRemove && (
                <Center
                    position="absolute"
                    zIndex={1}
                    p="4px"
                    rounded="full"
                    bottom="-8px"
                    right="-8px"
                    borderWidth={1}
                    borderColor="white"
                    background={`${channelConfig.colorScheme ?? "blueGray"}.500`}
                >
                    <Pressable onPress={handleRemove}>
                        <Tooltip label={logoUrl ? `Unlist on ${channel}` : "Remove channel"}>
                            <CloseIcon color="white" size="8px" />
                        </Tooltip>
                    </Pressable>
                </Center>
            )}
            {typeof status !== "undefined" && (
                <>
                    {status === "listed" ? (
                        <Dot position="absolute" top={0} right={0} bg="success.600" />
                    ) : (
                        <Dot position="absolute" top={0} right={0} bg="blueGray.400" />
                    )}
                </>
            )}
        </View>
    );
};

export default memo(ChannelLogo);
